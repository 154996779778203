.aboutUsWrapper {
  padding: 1vh 9vw 1vh 5vw;
  background-color: #e9f4fd !important;
}
.company__card-row {
  margin: 10% 0;
}
.company__card-row img {
  height: auto;
  width: 100%;
  object-fit: cover;
}
.company__card-row h3 {
  font-size: 2rem;
  font-weight: bold;
}
.company__card-row p {
  font-size: 1.2rem;
}
.contact__address a {
  text-decoration: underline !important;
  color: #4472c4 !important;
}

.company__card-grid-images {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.company__main-img-col img {
  height: 350px;
  width: 75%;
  border-radius: 20px;
}
.company__main-img-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.company__card-grid-images {
  height: auto;
  max-width: 35vw !important;
}
.company__grid-images-down {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.company__grid-img1 img {
  /* width: 76.8%; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.company__grid-img2 img {
  border-bottom-left-radius: 20px;
  margin-top: -2%;
  width: 300px;
}

.company__grid-img3 img {
  width: 100%;
}
.company__grid-img3 img {
  border-bottom-right-radius: 20px;
  height: 200px;
  width: 110%;
  margin-left: -10%;
}

@media screen and (max-width: 820px) {
  .company__card-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .company__grid-img1 img,
  .company__grid-img2 img,
  .company__grid-img3 img {
    border-radius: 0px !important;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .company__main-img-col img {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }
  .company__card-grid-images {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .company__grid-img1 img,
  .company__grid-img2 img,
  .company__grid-img3 img {
    height: 200px;
    width: 400px;
    margin: 0;
  }
}
@media screen and (max-width: 550px) {
  .aboutUsWrapper {
    padding: 2vh 5vw;
  }
  .company__card-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding: 3%;
  }
}

@media screen and (max-width: 460px) {
  .company__grid-img1 img,
  .company__grid-img2 img,
  .company__grid-img3 img {
    height: 200px;
    width: 350px;
    margin: 0;
  }
}
@media screen and (max-width: 380px) {
  .company__grid-img1 img,
  .company__grid-img2 img,
  .company__grid-img3 img {
    height: 200px;
    width: 300px;
    margin: 0;
  }
}
@media screen and (max-width: 320px) {
  .company__grid-img1 img,
  .company__grid-img2 img,
  .company__grid-img3 img {
    height: 200px;
    width: 250px;
    margin: 0;
  }
}
@media screen and (max-width: 250px) {
  .company__grid-img1 img,
  .company__grid-img2 img,
  .company__grid-img3 img {
    height: 200px;
    width: 200px;
    margin: 0;
  }
}
