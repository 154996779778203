.footer {
  padding: 2% 4%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
    0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
    0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}
.footer__icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.footer__icons a {
  font-size: 1.5rem;
  margin-right: 2rem;
}
.footer__pages {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.footer__copyright-text {
  margin-bottom: -2rem;
  text-align: center;
}
.footer__copyright-text,
.footer__pages a {
  font-size: 0.8rem;
}
.footer__legaldetails {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5vh;
  padding-top: 2vh;
  text-align: center;
}
.foot__legalText {
  font-size: 0.9rem;
  padding-right: 4px;
  padding-left: 4px;
}

@media screen and (max-width: 550px) {
  .footer__pages,
  .footer__copyright-text,
  .footer__icons {
    justify-content: center;
    margin: 0;
  }
  .footer__copyright-text {
    margin-bottom: 0rem;
  }
  .footer__copyright-text p {
    text-align: center;
  }
}
