.cardMedia:hover {
  cursor: pointer;
}
.modalList {
  list-style-type: none;
}
.modalList li {
  list-style-type: none;
  display: inline;
}
.learnMore {
  font-weight: bold;
  font-size: 0.8 rem;
}
