.navBar {
  display: flex;
  padding: 5px 0;
  border-bottom: 1px solid #c2c2c2;

  .navItem {
    font-size: 16px;
    color: #0072a3;
    cursor: pointer;
    margin: 0 18px;
  }
}

.heading {
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 4px;
  font-size: 20px;
  color: rgb(84, 112, 105);
  font-weight: 500;
  padding-top: 10px;
}

.subHeading {
  font-size: 16px;
  color: #565656;
  font-weight: 500;
  margin-top: 10px;
}

.para {
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #565656;
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0;
  padding: 0 10px;
  margin: 10px;

  .logo {
    width: 800px;
  }
}

.listWrapper {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
  color: #565656;
  letter-spacing: 0;
  line-height: 20px;
  word-break: break-word;
  margin-left: 15px;
}
