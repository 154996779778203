.howwedo {
  padding: 2%;
}
.howwedo__contents h3 {
  font-size: 2rem;
}
.howwedo__contents h2 {
  margin: 4% 0;
  font-size: 1.5rem;
  font-weight: bolder;
}
.howwedo__contents p {
  font-size: 1.2rem;
  margin: 4% 0;
}
.howwedo__contents button {
  box-shadow: none !important;
  background-color: #05758a;
  border: none;
  outline: none;
  color: #fff;

  padding: 10px 24px;
  border-radius: 24px;
}
.howwedo__contents button:hover {
  background-color: #1c9cad;
}
.howwedo__image img {
  margin: 0;
  padding: 0;
  width: auto;
  height: 400px;
  object-fit: cover;
}
.howwedo__image {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.additional {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
}

.howwedo__card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 0;
}
.howwedo__card-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.howwedo__card-content h4 {
  font-size: 1rem;
  width: 90%;
  margin-left: -6%;
  margin-top: 1%;
}
.howwedo__card-content h4:first-child {
  color: lightskyblue;
}
.howwedo__card-content h4 a {
  font-size: 1rem;
  color: lightskyblue !important;
}
.howwedo__card-img img {
  height: 60px;
  width: auto;
  border: none;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.howwedo__card-date {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid lightblue;
  margin: 4% 2%;
  border-radius: 12px;
  width: 15% !important;
  height: 3.7rem;
}

.learn h4 {
  margin-top: -1%;
}
.learn h3,
.events h3 {
  margin-bottom: 7%;
}
.news h3 {
  margin-bottom: 5%;
}

@media screen and (max-width: 900px) {
  .howwedo div {
    margin: 3% 0;
  }
  .howwedo__image {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .howwedo__contents h2 {
    font-size: 2.5rem;
  }
  .howwedo__contents h3 {
    font-size: 1rem;
  }
  .howwedo__contents p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 700px) {
  .news,
  .events,
  .learn {
    margin: 3% 0;
  }
}

@media screen and (max-width: 550px) {
  .additional {
    margin-top: 10% !important;
  }
  .news,
  .events,
  .learn {
    margin: 1% 0;
  }
  .howwedo__image {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .howwedo__image img {
    height: auto;
    width: 100%;
  }
  .howwedo__card-date {
    margin: 2% 3%;
  }
}
