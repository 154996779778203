.serviceWrapper {
  padding: 0 5vw 3vh 5vw;
  margin-top: 0 !important;
  /* margin: 0 5rem 0 5rem; */
  background-color: #e9f4fd;
}
.serviceHeading {
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
  color: #223d9c;
  padding-top: 5vh;
  font-size: 2rem;
}
.service-subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  /* line-height: 32px; */
  color: #000000;
  padding-bottom: 1vh;
  padding-top: 3vh;
}

.liServiceStyle {
  font-style: normal;
  font-weight: 550;
  font-size: 1.1rem;
  /* line-height: 39px; */
  padding-bottom: 2vh;
  color: #000000;
}
.liExtraHeight {
  height: fit-content;
}
.technologyTable {
  background-color: #81c5d5;

  /* box-sizing: border-box; */
}
.technologyList {
  border: 2px solid white;
}
.technologyListview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start, space-evenly;
  margin: 10px;
  padding-left: 6px;
  list-style-type: none;
}
