.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  width: 275px;
  border: 1px solid #4b9990;
  box-shadow: #4b9990 0 1px 3px, #1b1f2326 0 0 0 1px;

  .topSection {
    padding: 20px 20px;
    background-color: #4b9990;

    .title {
      color: #0a0a0a;
      font-size: 22px;
      line-height: 20px;
      font-weight: 700;
    }

    .size {
      color: #0a0a0a;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .bottomSection {
    padding: 20px 20px;
    flex-grow: 1;

    .price {
      font-size: 36px;
      font-weight: 700;
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-around;
      padding-bottom: 15px;
      border-bottom: solid 1.5px #808080;

      .button1 {
        margin-top: 10px;
        background-color: #2db3ba;
        border: none;
        padding: 3px 20px;
        border-radius: 5px;
        color: #fff;
      }

      .button2 {
        margin: 10px 0 0 5px;
        border: solid 2px orange;
        background-color: #fff;
        padding: 3px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #547069;
      }
    }

    .summary {
      font-size: 14px;
      font-weight: 600;
      color: #808080;
    }

    .heading {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      margin-top: 10px;
    }

    .item {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: #808080;
      margin-left: 5px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .logo {
      width: 80px;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    .vendor {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: #808080;
    }
  }
}
