.cardMedia {
  transform: scale(0.9);
  object-fit: contain;
}
.learnMore {
  position: static;
  font-weight: bold;
  font-size: 0.8 rem;
  bottom: 3px;
}

.texttoLeft {
  text-align: left;
}

.CardDetails {
  text-align: left;
  color: #8693a5;
}

.decriptionText {
  font-size: 1rem;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cards {
  min-width: 20vw;
  border: 10px;
}

/* @media (max-width: 1200px) {
  .cards {
    width: 25vw;
  }
}
@media (max-width: 900px) {
  .cards {
    width: 30vw;
  }
}
@media (max-width: 600px) {
  .cards {
    min-width: 35vw;
  }
} */
