.partners__strategy-content {
  margin-bottom: 3%;
}
.partners__strategy-content h2,
.partners__partner-content h3 {
  font-weight: bold;
  text-align: center;
  font-size: 2rem;
}
.partners__strategy-content p,
.partners__partner-content {
  margin: 3% 0;
}
.partners__form-container h3 {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}
.partners__partner-content p a {
  text-decoration: underline !important;
  color: #4472c4 !important;
}
.form {
  margin: 3% 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form input {
  width: 30rem;
}
.partners__button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.partners__button button {
  background-color: #05758a;
  box-shadow: none !important;
  color: white;
  border: none;
  outline: none;

  padding: 10px 24px;
  border-radius: 24px;
}
.partners__button button:hover {
  background-color: #1c9cad;
}

@media screen and (max-width: 550px) {
  .form input {
    width: 24rem;
  }
}
@media screen and (max-width: 400px) {
  .form input {
    width: 18rem;
  }
}
@media screen and (max-width: 400px) {
  .form input {
    width: auto;
  }
}
