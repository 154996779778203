.HeaderWrapper {
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  background-color: #4b9990;
  text-align: center;
  height: 25vh;
  justify-items: center;
  align-items: center;
}
.ContactUsHeading {
  font-size: calc(1.5rem + 0.8vw);
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  padding-bottom: 3vh;
  color: #ffffff;
}
.ContactUsSubheading {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 29px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-bottom: 3vh;
  max-width: 70vw;
}
.button {
  background-color: #d9d9d9; /* Green */
  border: none;
  color: 3B3A6F;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;

  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  border-radius: 2px;
}

@media (max-width: 800px) {
  .HeaderWrapper {
    height: 45vh;
  }
}
@media (max-width: 500px) {
  .HeaderWrapper {
    height: 55vh;
  }
}
