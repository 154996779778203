.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e9f4fd;
  /* padding: 2rem 6rem; */
  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
  z-index: 100;
}
.navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 4vw;
}
.navbar-links_logo {
  margin-left: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.navbar-links_logo img {
  border: none;
  background-color: #e9f4fd;
}
.navbar-links_logo h1 {
  font-family: var(--font-family-primary);
  margin-left: 5px;
}

.navbar-links_logo img {
  min-width: 250px;
  height: 72px;
}
.navbar-links_container {
  /* flex: 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
  color: #000;
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.navbar-sign button,
.navbar-menu_container button {
  padding: 0.5rem 1rem;
  background-color: #ff4820;
  color: #000;
  font-size: 18px;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  z-index: 100;

  text-align: end;
  background: #f5f5f4;
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.navbar-menu_container p {
  margin: 1rem 0;
}

.navbar-menu_container-links-sign {
  display: none;
}

@media screen and (max-width: 1050px) {
  .navbar-links_container {
    display: none;
  }
  .navbar-menu {
    display: flex;
  }
}
@media screen and (max-width: 700px) {
  .navbar {
    padding: 2rem 4rem;
  }
}
@media screen and (max-width: 550px) {
  .navbar {
    padding: 2rem;
  }
  .navbar-sign {
    display: none;
  }
  .navbar-menu_container {
    top: 20px;
  }
  .navbar-menu_container-links-sign {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .navbar-links_logo img {
    height: auto;
  }
}
