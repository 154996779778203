.cloudMarket {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #223d9c;
  padding-top: 18vh;
}
.cloudMarket-subheading {
  /* padding-top: 7vh; */
  /* padding-bottom: 5vh; */

  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 44px;
  color: #000000;
}
.default {
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 39px;

  color: #000000;
}
.WrapperForCloudMarket {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 500px) {
  .cloudMarket {
    padding-top: 6vh;
  }
  .cloudMarket-subheading {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}
