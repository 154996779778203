.topSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #4b9990;
  height: 30vh;

  .topHeader {
    font-size: 2.625rem;
    line-height: 3.375rem;
    font-weight: 700;
    color: #fff;
  }

  .topSubHeader {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
    color: #fff;
  }
}

.wrapper {
  display: flex;

  > div:first-child {
    width: 25%;
  }
}
