* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: var(--font-family-primary);
  color: #0a2b66;
}
p {
  font-family: var(--font-family-secondary);
}

a {
  color: unset !important;
  text-decoration: none !important;
}

/* .section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 2rem 6rem;
} */

.newsflash p {
  transition: ease-in-out 0.5s;
}

.dropdown-toggle::after {
  display: none !important;
}

.Products_products__image__\+op-W img {
  margin-top: 15%;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media screen and (max-width: 900px) {
  .section__margin {
    margin: 2rem 4rem;
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }
  .section__margin {
    margin: 3rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  .section__margin {
    margin: 3rem 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .newsflash {
    display: none;
  }
}

/* CUSTOM CSS FOR BOOTSTRAP */
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: none;
  color: #fff;
}
.card {
  border: none;
}

/* Changing Image location on mobile devices */
@media screen and (max-width: 768px) {
  .mobile__active-hide-img {
    display: none !important;
  }
}
@media screen and (min-width: 770px) {
  .mobile__active-show-img {
    display: none;
  }
}
