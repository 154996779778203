h3 {
  font-size: 1.8rem;
  font-weight: bold;
}
p {
  font-size: 1.2rem;
}
.icons {
  margin-bottom: 3px;
}
.services__software-content p,
.services__tools-content p {
  width: 80%;
  margin: 0 auto;
}

.services__software-content {
  margin: 3% 0;
  text-align: center;
}

.services__talktous-banner {
  margin: 3% 0;
  padding: 4%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #2db3ba;
}

.services__talktous-banner button {
  padding: 16px 24px;
  background-color: white;
  outline: none;
  border: none;
  box-shadow: none !important;
}
.services__talktous-banner button:hover {
  background-color: white;
  color: #000;
}
.services__talktous-banner h4 {
  font-weight: bold;
  color: #fff;
}

.services__tools-content {
  margin: 3% 0;
  text-align: center;
}

.services__technologies-card {
  margin: 5% 7%;
}
.services__technologies-card h3 {
  font-size: 1.2rem;
  margin: 5% 0;
  color: #1554c0;
}
.services__technologies-card ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.services__technologies-card li {
  margin: 2% 5%;
  height: 1.7rem;
  width: auto;
  padding: 0 3%;
  font-size: 1rem;
  background-color: #e9f1fc;
  text-align: center;
  border-radius: 12px;
  outline: 1px solid lightgray;
}
.services__languages,
.services__frameworks,
.services__tech-cols div {
  background-color: #e9f1fc;
  border: 2px solid white;
}

.services__delivery-partners {
  margin: 3% 0;
  text-align: center;
}
@media screen and (max-width: 1200px) {
  .services__technologies-card ul li {
    margin: 0 1%;
  }
  .services__technologies-card ul {
    margin-left: -10%;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 900px) {
  .services__technologies-card ul li {
    font-size: 0.8rem;
  }

  .services__software-content,
  .services__talktous-banner,
  .services__tools-content,
  .services__technologies-card,
  .services__delivery-partners {
    margin: 5%;
  }
  .services__talktous-banner h4 {
    max-width: 75%;
    font-size: 1.2rem;
  }
  .services__talktous-banner a button {
    font-size: 1rem;
  }
  /* .services__technologies-card ul {
    overflow-x: scroll;
  } */
}

@media screen and (max-width: 700px) {
  .services__technologies-card ul {
    justify-content: flex-start;
  }
  .services__talktous-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .services__talktous-banner h4 {
    max-width: 100%;
    margin-bottom: 3%;
    text-align: center;
  }
}
@media screen and (max-width: 500px) {
  h3,
  p {
    width: 100% !important;
    text-align: left;
    margin: 10% 0 !important;
  }
}

@media screen and (max-width: 380px) {
  .services__technologies-card ul {
    overflow-x: scroll;
  }
}
