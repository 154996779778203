.projectNeem {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #223d9c;
  padding-top: 15vh;
}
.projectNeem-subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 39px;
  color: #000000;
  /* padding-bottom: 7vh;
  padding-top: 10vh; */
}
.default {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 39px;
  color: #000000;
}
.WrapperForCloudMarket {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 600px) {
  .WrapperForCloudMarket {
    width: 100%;
  }
}
