.brief {
  position: static;
  top: 0;
  width: 100%;
  min-height: 15vh;
  padding: 20px;
  display: flex;
  justify-content: center;
  background-color: #4b9990;
}
.text {
  padding-top: 1vh;
  color: aliceblue;
  font-size: 1.5rem;
  max-width: 60vw;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  text-align: center;
}
