/* body {
  
  height: 18vh;
  background-color: yellow;
} */
.partnerNavWrap {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1274px;
  padding: 0 25px;
  width: 100%;
}
.partners-nav {
  display: block;
  line-height: 25.6px;
  position: sticky;
  top: 0;
  z-index: 90;
  background-color: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.09),
    inset 0 -1px 0 0 hsla(0, 0%, 79%, 0.5);
  width: 100%;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
}
.partners-nav.fixed {
  position: fixed;
  top: 0px;
}

.ulPartnerBar {
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.liPartnerBar {
  display: inline;
  font-size: 1rem;
  font-weight: 700;

  letter-spacing: 0.02em;
  line-height: normal;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
  /* 
  margin: 0 1rem;
  color: #505769;
  font-size: 1.2rem;
  font-weight: bold; */
}

.liPartnerBar:hover {
  color: #1e6ae4;
  cursor: pointer;
}

@media (max-width: 560px) {
  .partners-nav {
    display: none;
  }
}
.wrapper {
  display: block;
  padding-top: 40px;
  padding-bottom: 150px;
  background-color: #f0f5ff;
}

.techPartner {
  position: relative;
  min-height: 50vh;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-top: 4vh;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
}
hr {
  border-top: 4;
  display: block;
  height: 0.8px;
  padding: 0;
  position: relative;
}
.hr-tiny {
  border-radius: 2px;
  border: 3.5px solid #1e54d5;
  margin: 0 auto 20px;
  width: 50px;
  opacity: 1;
}
.partnerButton {
  display: inline-block;
  background: #1e54d5;
  border-radius: 8px;
  padding: 20px 17px;
  letter-spacing: 0.05em;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  padding: 20px 17px;
  /* line-height: 14px; */
  text-align: center;
  box-sizing: border-box;
  opacity: 1;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s;
  /* font-weight: 500; */
}
.partnerButton:hover {
  box-shadow: 0 7px 16px -3px rgba(0, 0, 0, 0.3);
  background-color: #0c44ca;
}
.cardRow {
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 5vw;
  padding-bottom: 5vw;
  padding-top: 7vh;
}
.partnerHeading {
  text-align: center;
  font-size: 2.5rem;
  line-height: 3.6rem;
  font-weight: 700;
  margin-bottom: 24px;
}
.partnerSubHeading {
  font-size: 1.5rem;
  line-height: 1.6em;
  margin: 0 auto 31px;
  max-width: 80vw;
  opacity: 0.7;
  text-align: center;
}
