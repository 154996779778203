.footerWrapper {
  padding-top: 7vh;
  display: flex;
  flex-direction: column;
  background-color: #4b9990;
  text-align: center;
  min-height: 40vh;
  justify-items: center;
  align-items: center;
}
.ContactUsHeading {
  font-size: 2.7rem;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  padding-bottom: 3vh;
  color: #f3a221;
}
.ContactUsSubheading {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;

  color: #ffffff;
  padding-bottom: 3vh;
}
.button {
  background-color: #d9d9d9; /* Green */
  border: none;
  color: 3B3A6F;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1.5px;
  border-radius: 2px;
}
@media (max-width: 800px) {
  .HeaderWrapper {
    height: 45vh;
  }
}
@media (max-width: 500px) {
  .HeaderWrapper {
    height: 55vh;
  }
  /* .ContactUsSubheading {
    text-align: center;
    font-size: 1rem;
    min-width: 70vw;
  } */
}
