.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .title {
    color: #fff;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    padding: 5px 20px;
    margin-bottom: 20px;
    border: solid 1px #607d8b;
    background-color: #607d8b;
  }

  .cardsWrapper {
    display: flex;
    column-gap: 40px;
    row-gap: 40px;
    flex-wrap: wrap;
  }
}
