.ulstyle {
  list-style: none;
  display: flex;
  margin: 0;
  padding-left: 0;
  padding-top: 5vh;
  padding-bottom: 2vh;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}
.ulOverride {
  margin: 0;
  padding-left: 0;
  padding-top: 5vh;
  padding-bottom: 2vh;
}
.listyle {
  display: inline;
}
.blue {
  color: #223d9c;
  font-size: 2.5rem;
  text-align: left;
}
.yellow {
  color: #cfb21c;
  padding-left: 1vw;
  font-size: 2.5rem;
  text-align: left;
}
.quote {
  padding-top: 2vh;
  padding-bottom: 3vh;
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #3f7256;
}
.WrapperForText {

  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: flex-start;
}
.Description1 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 39px;
  color: #000000;
  padding-bottom: 3vh;
}
.Description2 {
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 39px;
  color: #000000;
  padding-bottom: 1vh;
}
.DemoButton {
  margin-right: 7vw;
  background-color: #1b4686 !important;
  border-radius: 60px !important;
}
.buttonText {
  text-align: center;
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
}

/* @media (max-width: 800px) {
  .DemoButton {
    width: 20vw;
    height: 5vh;
  }
}
@media (max-width: 450px) {
  .DemoButton {
    width: 15vh;
    height: 15vh;
  }
}
@media (max-height: 600px) {
  .DemoButton {
    width: 15vw;
    height: 15vh;
  }
} */
