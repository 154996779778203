.container {
  margin: 10px 50px;
  border: 1px solid #c2c2c2;
  padding: 0 20px 20px;

  .headerSection {
    display: flex;
    border-bottom: 1px solid #c2c2c2;

    .logoContainer {
      display: flex;
      align-items: center;
      flex: 0 0;
      padding: 0 10px;

      .logo {
        width: 100px;
      }
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      padding: 0 10px 26px;
      width: 70%;

      .title {
        font-size: 20px;
        line-height: 24px;
        color: #0072a3;
        padding-top: 22px;
        font-weight: 600;
      }

      .summary {
        font-size: 14px;
        color: #666666;
        margin-top: 7px;
        font-style: italic;
        word-break: break-word;
        text-align: justify;
      }

      .vendor {
        font-size: 14px;
        line-height: 24px;
        color: #157db7;
        margin-right: 5px;
        cursor: pointer;
      }

      .tagContainer {
        display: flex;

        .tag {
          border-radius: 20px;
          border: 1px solid #9460b8;
          color: #565656;
          font-size: 11px;
          cursor: not-allowed;
          pointer-events: none;
          padding: 0.2rem 0.6rem;
          margin: 5px 15px 5px 0;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 15px;

      .button1 {
        margin-top: 10px;
        background-color: #2db3ba;
        border: none;
        padding: 3px 20px;
        height: 35px;
        border-radius: 5px;
        color: #fff;
      }

      .button2 {
        margin: 10px 0 0 5px;
        border: solid 2px orange;
        background-color: #fff;
        height: 35px;
        padding: 3px 10px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        color: #547069;
      }
    }
  }

  .navBar {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #c2c2c2;

    .navItem {
      font-size: 14px;
      color: #565656;
      cursor: pointer;
      margin: 0 18px;
    }
  }
}
