.home {
  background: #2db3ba;
  padding: 7% 1% 7%;
}
.home__carousel {
  height: 30vh;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  width: 30vw;
  align-items: center;
  justify-content: space-between;
}
.home__carousel-item p {
  font-family: var(--font-family-secondary);
  font-size: 2.5rem;
  color: #fff;
  width: 75%;
  text-align: center;
}
.home__carousel-item button {
  border-radius: 24px;
  box-shadow: none !important;
}
.home__carousel-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 900px) {
  .home__carousel-content p {
    width: 90%;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .home__carousel-content p {
    font-size: 1.8rem;
    width: 70%;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .home__carousel {
    height: 250px;
    width: auto;
  }
  .home__carousel-content p {
    font-size: 1.5rem;
    width: 75% !important;
    text-align: center;
  }
}

@media screen and (max-width: 448px) {
  .home__carousel {
    height: 270px;
    width: auto;
  }
  .buttonContainer {
    min-width: 70vw;
  }
}

@media screen and (max-width: 382px) {
  .home__carousel {
    height: 310px;
    width: auto;
  }
}
