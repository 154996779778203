@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

:root {
  --font-family-primary: "Roboto", sans-serif !important;
  --font-family-secondary: "Roboto", sans-serif !important;
  /* 
  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%); */

  /* --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71; */
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

html {
  background-color: #e9f4fd;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
