.container {
  padding: 20px;

  .filterTitle {
    font-size: 18px;
    font-family: Mulish, Mulish-local, sans-serif;
    font-weight: 800;
    margin: 1.5rem 0 1.125rem;
    border-left: 3px solid #2db3ba;
    padding-left: 0.625rem;
  }
}
