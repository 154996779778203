.whatwedo {
  padding: 3% 3% 0 3%;
  margin: 3% 3% 0 3%;
}
.whatwedo h2 {
  text-align: center;
  font-size: 2rem;
}
.whatwedo h4 {
  font-size: 1.5rem;
}
.whatwedo__cards {
  margin-bottom: 7%;
}
.whatwedo__cards p {
  font-size: 18px;
}
.whatwedo__cards-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box {
  height: 50%;
  width: 10px;
  position: relative;
  border-bottom: 5px solid orangered;
  background: orangered;
}
.border {
  position: absolute;
  top: 50%;
  bottom: 0;
}

.whoweare {
  margin: 3% 0;
  padding: 2%;
}
.whoweare__contents h2 {
  margin: 4% 0;
  text-align: left;
  font-size: 1.5rem;
  font-weight: bolder;
}
.whoweare__contents h3 {
  font-size: 2rem;
}
.whoweare__contents p {
  font-size: 1.2rem;
  margin: 4% 0;
}
.whoweare__contents button {
  box-shadow: none !important;
  background-color: #05758a;
  border: none;
  outline: none;
  color: #fff;

  padding: 10px 24px;
  border-radius: 24px;
}
.whoweare__contents button:hover {
  background-color: #1c9cad;
}
.whoweare__contents {
  width: 50%;
}
.image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.image img {
  margin: 0;
  padding: 0;
  width: auto;
  height: 400px;
  object-fit: cover;
}

@media screen and (max-width: 900px) {
  .whatwedo__cards div {
    margin: 3% 0;
  }
  .whoweare div {
    margin: 3% 0;
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .whoweare__contents h2 {
    font-size: 2.5rem;
  }
  .whoweare__contents h3 {
    font-size: 1rem;
  }
  .whoweare__contents p {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 700px) {
  .box {
    display: none;
  }
  .whatwedo__cards div {
    margin: 3% 0;
  }
  .whoweare div {
    margin: 3% 0;
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 550px) {
  .whatwedo__cards div {
    margin-bottom: 3%;
  }
  .whoweare div {
    margin: 3% 0;
  }
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .image img {
    height: auto;
    width: 100%;
  }
}
