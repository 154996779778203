.careersWrapper {
  margin: 0 3rem;
}

.btn__group {
  display: flex;
  margin: 1.5rem 0;
  overflow-x: auto;
}
.linkStyle {
  text-decoration: underline !important;
  color: #547069 !important;
  font-size: 1.2rem;
}
.tab {
  background: transparent;
  padding: 0.5rem 3rem;
  font-size: 20px;
  font-weight: 700;
  color: #547069;
  white-space: nowrap;
  cursor: pointer;
}
.tab:first-letter {
  text-transform: capitalize;
}

.active {
  background: #e9f4fd;
  box-shadow: inset 0 -4px #547069;
  color: #1b1a21;
}

.header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;
  margin-bottom: 2rem;
}
.subHeadings {
  font-weight: 600;
}

.job {
  border-top: 1px solid #e6e5ea;
  cursor: pointer;
  display: block;
  padding: 25px 40px 25px 20px;
  margin: 0 5rem;

  .location {
    display: flex;
    align-items: center;

    > span {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-left: 5px;
    }
  }

  .jobTitle {
    color: #547069;
    font-size: 20px;
    font-weight: 600;
  }

  .skill {
    padding-left: 20px;
    color: #808080;
  }
}

.job:hover {
  background-color: #e9f4fd;
}

.btn__group::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.btn__group::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.btn__group::-webkit-scrollbar-thumb {
  background: #ececec;
}

/* Handle on hover */
.btn__group::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

@media screen and (max-width: 768px) {
  .job {
    padding: 25px 20px 25px 20px;
    margin: 0 1rem;
  }
}
