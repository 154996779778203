.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #edf1f2;

  .filterTitle {
    color: #0a0a0a;
    font-size: 32px;
    margin-top: 24px;
    line-height: 36px;
    font-weight: 700;
  }
}
