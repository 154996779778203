.blogHeaderWrapper {
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  background-color: #4b9990;
  text-align: center;
  min-height: 20vh;
  justify-items: center;
  align-items: center;
}
.blogHeading {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  padding-bottom: 1vh;
  color: white;
}
.blogSubheading {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 1px;
  color: #ffffff;
  padding-bottom: 3vh;
  max-width: 70vw;
}
.ButtonPostion {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  margin-left: 8vw;
}

.blogButton {
  /* Green */

  text-decoration: none;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  background-color: black;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  /* min-width: 20vw; */
}
.blogButton-blue {
  border: 4px solid #3ec1f4 !important;
}
.blogButton-orange {
  border: 4px solid orange !important;
}
.blogButton-green {
  border: 4px solid green;
}
.blogTitle {
  font-size: 2rem;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  padding-bottom: 1vh;
  color: #223d9c;
  margin-top: 5vh;
  max-width: 70vw;
}

.ButtonContainer {
  margin-bottom: 5vh;
  /* width: 100%; */
  padding: 10px;
  opacity: 1;
  color: white;
  /* z-index: 90; */
  background-color: black;
  padding: auto;
  margin-left: 0;
  margin-right: 0;
}

.sticky {
  display: inline;
  line-height: 25.6px;
  position: sticky;
  top: 0;
  z-index: 90;
  background-color: black;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.09),
    inset 0 -1px 0 0 hsla(0, 0%, 79%, 0.5);
  /* width: 150%; */
  /* border: 0 solid #e5e7eb; */
  /* box-sizing: border-box; */
}

.blogWrapper {
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-items: center;
  align-items: center;
}

@media (max-width: 900px) {
  .HeaderWrapper {
    height: 45vh;
  }
}
@media (max-width: 500px) {
  .HeaderWrapper {
    height: 55vh;
  }
}

@media (max-width: 1050px) {
  .ButtonContainer {
    display: none;
  }
}
